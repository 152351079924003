//shop.jsx
import React, { useState, useEffect, useRef } from "react";
import { PRODUCTS } from "../../products";
import { Product } from "./product";
import "./shop.css";

import character2 from "../../assets/character2.png";
import character4 from "../../assets/character4.png";
import character5 from "../../assets/character5.png";
import logo from "../../assets/dimesmgmt2.png"; // Import your logo image
import { CustomerReviews } from "../../components/reviews/reviews.jsx";

const characters = [character2, character4, character5];
const messages = ["Cookies Included", "Organically Farmed", "Made with US Proxy"]; // Text messages for speech bubble

export const Shop = () => {
    const [productCharacters, setProductCharacters] = useState({});
    const [loadedImages, setLoadedImages] = useState({});
    const [speechBubbleText, setSpeechBubbleText] = useState({}); // Track speech bubble text for each product
    const intervals = useRef({}); // Store intervals for each product

    useEffect(() => {
        const initialCharacters = PRODUCTS.reduce((acc, product) => {
            const randomCharacter = characters[Math.floor(Math.random() * characters.length)];
            acc[product.id] = randomCharacter;
            return acc;
        }, {});
        setProductCharacters(initialCharacters);

        // Initialize alternating messages for each product
        const initialText = PRODUCTS.reduce((acc, product) => {
            acc[product.id] = messages[0];
            return acc;
        }, {});
        setSpeechBubbleText(initialText);

        // Cleanup intervals on unmount
        return () => {
            Object.values(intervals.current).forEach(clearInterval);
        };
    }, []);

    const startAlternatingMessages = (productId) => {
        if (intervals.current[productId]) {
            clearInterval(intervals.current[productId]); // Clear any existing interval
        }

        let index = 0;
        intervals.current[productId] = setInterval(() => {
            index = (index + 1) % messages.length;
            setSpeechBubbleText((prevText) => ({
                ...prevText,
                [productId]: messages[index],
            }));
        }, 1500); // Alternate every 2 seconds
    };

    const handleMouseEnter = (productId) => {
        // Reset to the initial message and start alternating
        setSpeechBubbleText((prevText) => ({
            ...prevText,
            [productId]: messages[0],
        }));
        startAlternatingMessages(productId);
    };

    const handleMouseLeave = (productId) => {
        // Stop alternating messages
        if (intervals.current[productId]) {
            clearInterval(intervals.current[productId]);
        }
    };

    const handleImageLoad = (productId) => {
        setLoadedImages((prev) => ({ ...prev, [productId]: true }));
    };

    return (
        <div className="shop">
            <div className="shopTitle">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="products">
                {PRODUCTS.map((product) => (
                    <div
                        className="product-container"
                        key={product.id}
                        onMouseEnter={() => handleMouseEnter(product.id)}
                        onMouseLeave={() => handleMouseLeave(product.id)}
                    >
                        <Product data={product} />
                        <img
                            src={productCharacters[product.id]}
                            alt=""
                            className={`peeking-character ${loadedImages[product.id] ? "loaded" : ""}`}
                            onLoad={() => handleImageLoad(product.id)}
                        />
                        <div className="speech-bubble">{speechBubbleText[product.id]}</div>
                    </div>
                ))}
            </div>
            <CustomerReviews />
        </div>
    );
};
