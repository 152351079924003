import product1 from "./assets/1.png"
import product2 from "./assets/2.png"
import product3 from "./assets/3.png"
import product4 from "./assets/4.png"
import product5 from "./assets/5.png"
import product6 from "./assets/6.png"
import product7 from "./assets/7.png"
import product8 from "./assets/8.jpg"




export const PRODUCTS = [
    {
        id: 566,
        productName: "Lucy",
        price: 50.0,
        productImage: product1,
    },
    {
        id: 572,
        productName: "Violet",
        price: 50.0,
        productImage: product2,
    },
    {
        id: 573,
        productName: "Stella",
        price: 50.0,
        productImage: product3,
    },
    {
        id: 567,
        productName: "Daisy",
        price: 50.0,
        productImage: product4,
    },
    {
        id: 555,
        productName: "Leila",
        price: 50.0,
        productImage: product5,
    },
    {
        id: 560,
        productName: "Mia",
        price: 50.0,
        productImage: product6,
    },
    {
        id: 237,
        productName: "Joanna",
        price: 50.0,
        productImage: product7,
    },
    {
        id: 593,
        productName: "sparklyIvy_",
        price: 25.0,
        productImage: product8,
    }

]