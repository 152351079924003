import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./checkout.css";

export const Checkout = () => {
    const { state } = useLocation();
    const { paymentId } = state || {}; // Retrieve paymentId from state

    const [loadingMessage, setLoadingMessage] = useState("Waiting for payment confirmation...");

    const fetchPaymentStatus = async () => {
        try {
            

            // Call the backend `/api/payment-status` endpoint
            const response = await fetch(`https://my-react-app-iwkb.onrender.com/api/payment-status/${paymentId}`);

            if (!response.ok) {
                console.error(`Failed to fetch payment status: ${response.statusText}`);
                setLoadingMessage(`Error: ${response.statusText}`);
                return;
            }

            const { status, error } = await response.json();

            if (status) {
                console.log(`Payment status: ${status}`);
                setLoadingMessage(`Payment Status: ${status}`);

                if (status === "finished") {
                    setTimeout(() => {
                        setLoadingMessage("Payment confirmed! Redirecting...");
                    }, 5000);
                }
            } else {
                console.error(`Error fetching payment status: ${error}`);
                setLoadingMessage(`Error: ${error || "Unknown issue."}`);
            }
        } catch (error) {
            console.error("Error fetching payment status:", error);
            setLoadingMessage("Error fetching payment status.");
        }
    };

    useEffect(() => {
        if (paymentId) {
            console.log(`Started polling for Payment ID: ${paymentId}`);

            const interval = setInterval(() => {
                fetchPaymentStatus();
            }, 5000); // Poll every 5 seconds

            return () => {
                console.log("Stopped polling for payment status.");
                clearInterval(interval);
            };
        } else {
            setLoadingMessage("Invalid Payment ID.");
            console.error("Invalid Payment ID provided.");
        }
    }, [paymentId]);

    return (
        <div className="checkout-page">
            <h1>Checkout</h1>
            <p>{loadingMessage}</p>
        </div>
    );
};
