// src/components/CustomerReviews.jsx
import React from "react";
import "./reviews.css"; // Create a CSS file for styling if needed

// Array of customer reviews
const reviews = [
    {
        id: 1,
        name: "TODOROV - from Telegram",
        review: "Adam has the best reddit accounts in the game always delivers with high quality karma and speedy results BIG VOUCH, spent 135 for 3 accs", 
    },
    {
        id: 2,
        name: "Majeta Stopic - from Telegram",
        review: "Thank you for the account, delivery + answer was really fast 👍🏻",
    },
    {
        id: 3,
        name: "Palve Djordjevic - from Telegram",
        review: "Just bought 2 accs from Adam both work tottaly fine as well as the emails, great customer support as well🤝💪",
    },
    {
        id: 4,
        name: "Velizar Dimitrov - from Telgram",
        review: "I purchased a Reddit account from Adam, and I was impressed with how smooth and secure the process was. The account was exactly as described, with a solid history and karma. The seller communicated well, answered all my questions, and made the transfer easy. Highly recommend Adam for a trustworthy and hassle-free experience 🔥",
    },
    {
        id: 5,
        name: "NintenEX - from Telgram",
        review: "Adam sent me the Reddit accounts immediately, gave me instructions on the accounts, supplied cookies with the accounts, and did not require a MM to provide the accounts. I sent half up front and he sent the first account. Trustworthy and reliable :)",
    },
    {
        id: 6,
        name: "Obsession Empire - from Telgram",
        review: "so far i have bought 3 accounts from Dimes and everything has been perfect :)))",
    }
    // Add more reviews as needed
];

export const CustomerReviews = () => {
    return (
        <div className="reviews-section">
            <h2>What our customers say...</h2>
            <div className="reviews-list">
                {reviews.map((review) => (
                    <div key={review.id} className="review-card">
                        <h3>{review.name}</h3>
                        <p>{review.review}</p>
                        <div className="rating">{"⭐".repeat(review.rating)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
