import React, { useContext, useState } from "react";
import { ShopContext } from "../../context/shop-context";
import { useNavigate } from "react-router-dom"; // For navigation
import "./speech.css"; // Ensure you add CSS for the speech bubble

export const Product = (props) => {
    const { id, productName, price, productImage } = props.data;
    const { addToCart, cartItems } = useContext(ShopContext);
    const [showSpeechBubble, setShowSpeechBubble] = useState(false);
    const navigate = useNavigate();

    const cartItemAmount = cartItems[id];

    const handleAddToCart = () => {
        if (cartItemAmount > 0) {
            navigate("/cart"); // Navigate to the cart page
        } else {
            addToCart(id);
            setShowSpeechBubble(true); // Show the speech bubble
            setTimeout(() => setShowSpeechBubble(false), 2000); // Hide after 2 seconds
        }
    };

    return (
        <div className="product">
            <img src={productImage} />
            <div className="description">
                <p><b>{productName}</b></p>
                <p>${price}</p>
            </div>
            <button 
                className="addToCartBttn" 
                onClick={handleAddToCart}
            >
                {cartItemAmount > 0 ? "Go to cart" : "Add to Cart"}
            </button>
            {showSpeechBubble && (
                <div className="speech-bubble2">Added to cart!</div>
            )}
        </div>
    );
};
