import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/navbar';
import { Shop } from './pages/shop/shop';
import { Cart } from './pages/cart/cart';
import { Checkout } from './pages/checkout/checkout';
import { Success } from './pages/success/success';
import { Cancel } from './pages/cancel/cancel';
import SignIn from './pages/signin/signin';
import SignUp from './pages/signup/signup';
import { Contact } from "./pages/contact/contact";

import { ShopContextProvider } from './context/shop-context';

function App() {
  return (
    <div className="App"> 
      <ShopContextProvider>
        <Router> 
          <Navbar />
          <Routes>
            <Route path="/" element={<Shop />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/signin" element={<SignIn />} /> 
            <Route path="/signup" element={<SignUp />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
