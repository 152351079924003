//cartjsx
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { PRODUCTS } from "../../products";
import { ShopContext } from "../../context/shop-context";
import { CartItem } from "./cart-item";
import "./cart.css";
import { useNavigate } from "react-router-dom";

export const Cart = () => {
    const { cartItems, getTotalCartAmount } = useContext(ShopContext);
    const totalAmount = getTotalCartAmount();
    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        const email = localStorage.getItem("userEmail");
        if (email) {
            setUserEmail(email);
        }
    }, []);

    const handleCryptoPayment = async () => {
        const token = localStorage.getItem("token");
        const userEmail = localStorage.getItem("userEmail");
    
        if (!token || !userEmail) {
            alert("Please sign in to complete your purchase.");
            return;
        }
    
        try {
            const productIds = cartItemsWithQuantity.map((product) => product.id);
    
            const response = await axios.post(
                "https://my-react-app-iwkb.onrender.com/create-payment/",
                {
                    amount: totalAmount,
                    currency: "usd",
                    productIds,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            const { invoice_id, payment_id, order_id, invoice_url } = response.data;
    
            // Open the payment page for the user
            window.open(invoice_url, "_blank");
    
            // Navigate to the checkout page with all IDs
            navigate("/checkout", {
                state: {
                    invoiceId: invoice_id,
                    paymentId: payment_id,
                    orderId: order_id,
                },
            });
        } catch (err) {
            console.error("Error processing payment:", err.response?.data || err.message);
            alert("Failed to initiate the payment. Please try again.");
        }
    };
    
    

    const cartItemsWithQuantity = PRODUCTS.filter((product) => cartItems[product.id] > 0);

    return (
        <div className="cart">
            <div>
                <h1>Your Cart Items</h1>
                {cartItemsWithQuantity.length > 0 && (
                    <p className="account-info-message">
                        {userEmail
                            ? `Successfully signed in. Account info will be emailed to: ${userEmail} upon purchase!`
                            : "Account info will be emailed to user upon purchase, please make sure you're signed in!"}
                    </p>
                )}
            </div>
            <div className="cartItems">
                {cartItemsWithQuantity.length > 0 ? (
                    cartItemsWithQuantity.map((product) => (
                        <CartItem data={product} key={product.id} quantity={cartItems[product.id]} />
                    ))
                ) : (
                    <h1>Your cart is empty!</h1>
                )}
            </div>
            {totalAmount > 0 && (
                <div className="checkout">
                    <b><p>Subtotal: ${totalAmount.toFixed(2)}</p></b>
                    <button onClick={() => navigate("/")}>Continue Shopping</button>
                    <button onClick={handleCryptoPayment}>Pay With Crypto</button>
                </div>
            )}
        </div>
    );
};
