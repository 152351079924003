// src/pages/SignIn.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Header } from "../../components/header"; // Import Header

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://my-react-app-iwkb.onrender.com/api/auth/login', {
        email,
        password,
      });
  
      // Store the JWT token and email in localStorage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userEmail', email); // Store the user's email
  
      // Redirect the user after successful login
      navigate('/cart'); // Redirect to the cart or any protected route
    } catch (err) {
      setError('Invalid email or password');
    }
  };
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
      <form onSubmit={handleSubmit} style={{ maxWidth: '400px', width: '100%' }}>
      <Header />
        <h2>Sign In</h2>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign In</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        {/* Link to Sign Up page */}
        <p style={{ marginTop: '20px' }}>
          Don’t have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </form>
    </div>
  );
};

export default SignIn;
